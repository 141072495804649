import { render, staticRenderFns } from "./tabs-switch.html?vue&type=template&id=26cdab37&scoped=true&"
import script from "./tabs-switch.js?vue&type=script&lang=js&"
export * from "./tabs-switch.js?vue&type=script&lang=js&"
import style0 from "./tabs-switch.scss?vue&type=style&index=0&id=26cdab37&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26cdab37",
  null
  
)

export default component.exports